<template>
  <el-select
    ref="selRef"
    v-model="value"
    filterable
    clearable
    allow-create
    default-first-option
    placeholder="请输入或选择网域"
    @change="selectChange"
  >
    <el-option
      v-for="item in webDomains"
      :key="item"
      :label="item"
      :value="item"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "webDomain",
  props: {
    webDomains: {
      type: Array,
      default: () => [],
    },
    initWebDomain: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      value: "",
      options: [],
    };
  },
  methods: {
    selectChange(val) {
      console.log("selectChange", val);
      this.$emit("select-change", val);
    },
  },
  watch: {
    initWebDomain: {
      handler(val) {
        this.value = val;
        console.log("watch-initWebDomain-this.value", this.value);
      },
    },
  },
};
</script>

<style>
</style>