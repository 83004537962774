<template>
    <span style="margin-left:5px">
        <el-tooltip  placement="right" title="" width="460" trigger="hover" :enterable="false" effect="light">
            <!-- <div v-for="(item,index) in list" :key="index"  class="tipsContent">
                <p>{{item.tip}}</p>
                <div class="example">
                    <div>正确格式如下：</div>
                    <span>例如：{{item.link}}</span>
                </div>
            </div> -->
            <div slot="content">
                <el-image :src="img"></el-image>
            </div>
            
            <i class="el-icon-info" style="margin-left:0"></i>
        </el-tooltip>
    </span>
</template>
<script>
    // tips1Svg:require('@/assets/icon/audience1.svg'),
    //             tips2Svg:require('@/assets/icon/audience2.svg'),
    //             tips3Svg:require('@/assets/icon/audience3.svg'),
    export default {
        props:['type'],
        data() {
            return {
                img:require('@/assets/icon/webTips.png')
                // list:[
                //     {
                //         tip:'推广商品，增加商品的销售量，建议填写商品详情页网址',
                //         link:'https://www.sinoclick.com/components/icon-cn/'
                //     },
                //     {
                //         tip:'推广品牌，让更多用户访问你的官网，建议填写官网网址',
                //         link:'https://www.sinoclick.com'
                //     },
                // ]
            }
        },
    }
</script>
<style lang="scss" scoped>
.el-tooltip__popper{
    padding: 0;
}
    .tipsContent{
        padding: 10px;
        font-size: 12px;
        line-height: 2;
        p{
            color: #333;
        }
        .example{
            color: #999;
        }
    }
</style>